import React, { useState, useRef } from "react";
/* import BeforeLanding from '../BeforeLanding'; */
import "./Matches.css";
/* import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"; */
/* import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons"; */
/* import bufanda from "../../../Assets/Icons/bufanda.svg"; */
/* import bebida from "../../../Assets/Icons/refresco.svg"; */
/* import bestseats from "../../../Assets/Icons/bestasientos.svg"; */

/* var logoBstadium = require("../../../Assets/Icons/bstadium.png"); */
var versus = require("../../../Assets/Images/Group70.png");
/* var rectangulo = require("../../../Assets/Icons/rectangulo.png"); */
var noventa = require("../../../Assets/Icons/progress-90.jpg");
var setenta = require("../../../Assets/Icons/progress-75.jpg");
/* var cincuenta = require("../../../Assets/Icons/progress-50.jpg"); */

/* var levante = require("../../../Assets/Images/levante.png"); */
/* var villarreal = require("../../../Assets/Images/villarreal.png"); */
/* var celta = require("../../../Assets/Images/celta.png"); */
var madrid = require("../../../Assets/Images/madrid.png");
var girona = require("../../../Assets/Images/girona.png");
/* var malaga = require("../../../Assets/Images/malaga.png"); */
/* var burgos = require("../../../Assets/Images/burgos.png"); */
/* var granada = require("../../../Assets/Images/granada.png"); */
/* var rayo = require("../../../Assets/Images/rayo.png"); */
/* var valladolid = require("../../../Assets/Images/valladolid.png"); */
/* var lugo = require("../../../Assets/Images/lugo.png"); */
var atletico = require("../../../Assets/Images/atletico.png");
/* var alaves = require("../../../Assets/Images/alaves.png"); */
/* var athletic = require("../../../Assets/Images/bilbao.png"); */
/* var elche = require("../../../Assets/Images/elche.png"); */
var barcelona = require("../../../Assets/Images/barcelona.png");
/* var sevilla = require("../../../Assets/Images/sevilla.png"); */
/* var getafe = require("../../../Assets/Images/getafe.png"); */
/* var osasuna = require("../../../Assets/Images/osasuna.png"); */
var almeria = require("../../../Assets/Images/almeria.png");
var espanyol = require("../../../Assets/Images/espanyol.png");
/* var betis = require("../../../Assets/Images/betis.png"); */
var cadiz = require("../../../Assets/Images/cadiz.png");
/* var mallorca = require("../../../Assets/Images/mallorca.png"); */
/* var ponferradina = require("../../../Assets/Images/ponferradina.png"); */
/* var sociedad = require("../../../Assets/Images/sociedad.png"); */
/* var huesca = require("../../../Assets/Images/huesca.png"); */
var valencia = require("../../../Assets/Images/valencia.png");
/* var city = require("../../../Assets/Images/city.png"); */
/* var chelsea = require("../../../Assets/Images/chelsea.png"); */
/* var frankfurt = require("../../../Assets/Images/frankfurt.png"); */
 var celtic = require("../../../Assets/Images/celtic.png");
/* var tickets = require('../../../Assets/Icons/tickets.png'); */
/* var coins = require('../../../Assets/Icons/coins.png'); */

function Matches() {
  const [page, /* setPage */] = useState(1);
  const myRef = useRef();

  /* const pageOne = () => {
    setPage(1);
    myRef.current.scrollIntoView({ behavior: "smooth" });
  }; */

  /* const pageTwo = () => {
    setPage(2);
    myRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const pageThree = () => {
    setPage(3);
    myRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const pageFour = () => {
    setPage(4);
    myRef.current.scrollIntoView({ behavior: "smooth" });
  };

   const pageFive = () => {
    setPage(5);
    myRef.current.scrollIntoView({ behavior: "smooth" });
  }; */

  /* const next = () => {
    if (page < 5) {
      setPage(page + 1);
    }
    myRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const prev = () => {
    if (page > 1) {
      setPage(page - 1);
    }
    myRef.current.scrollIntoView({ behavior: "smooth" });
  }; */

  return (
    <div>
      <div ref={myRef} className="container-main">
        Partidos
      </div>

      {/*   <div className="container-popup">
        <img className="logo-bstadium" src={logoBstadium} alt=""></img>
        <div className="container-popup-right">
          <div>
            <h3>¡No te lo pierdas!</h3>
          </div>
          <div>
            <p>Sólo quedan 14 entradas con regalo:</p>
          </div>

          <div className="paragraph">
            Bebida
            <div className="circulo">
              <img className="icon-one" src={bebida} alt=""></img>
            </div>
            o Bufanda
            <div className="circulo">
              <img className="icon-two" src={bufanda} alt=""></img>
            </div>
          </div>
        </div>
      </div>  */}

      {/* Start of matches container */}
      <div className="slider">
        {page === 1 ? (
          <div className="container-one">
          
          
            {/* valencia - barcelona */}
            <div className="container container-desktop">
              <div className="date-b">
                <div className="progress-bar-b bar-desktop">
                  <img src={noventa} alt=""></img>
                  <h6 className="progress-bar-text">91% ocupación</h6>
                </div>
                <p>29/10/2022</p>
              </div>
              <div className="container-matches">
                <div className="container-team">
                  <h6 className="team-one">valencia</h6>
                  <img className="logo-team" src={valencia} alt=""></img>
                </div>
                <div>
                  <img className="logo-versus" src={versus} alt=""></img>
                </div>

                <div className="container-team-two">
                  <img className="logo-team" src={barcelona} alt=""></img>
                  <h6 className="team-two">barcelona</h6>
                </div>
              </div>

              <div className="container-end end-desktop">
                <div className="progress-bar bar-desktop">
                  <img
                    className="bar-progress-desktop"
                    src={noventa}
                    alt=""
                  ></img>
                  <h6 className="progress-bar-text bar-progress-desktop">
                    91% ocupación
                  </h6>
                  <p className="price-mobile-b">
                    <strong>desde 85€</strong>
                  </p>
                </div>
                <button
                  onClick={() =>
                    window.open(
                      "https://bstadium.es/entradas-de-partidos/entradas-valencia-cf-vs-fc-barcelona-temporada-22-23/"
                    )
                  }
                  className="btn-ticket"
                >
                  <div className="btn-display">
                    <h6 className="btn-text">Comprar entrada + Match Experience</h6>
                    {/* <img className="bufanda" src={bufanda} alt=""></img> */}
                  </div>
                </button>
                <div className="date-desktop">
                  <p>
                    <strong>desde 85€</strong>
                  </p>
                  <p>29/10/2022</p>
                </div>
              </div>
            </div>

            {/* madrid - girona */}
            <div className="container container-desktop">
              <div className="date-b">
                <div className="progress-bar-b bar-desktop">
                  <img src={setenta} alt=""></img>
                  <h6 className="progress-bar-text">72% ocupación</h6>
                </div>
                <p>30/10/2022</p>
              </div>
              <div className="container-matches">
                <div className="container-team">
                  <h6 className="team-one">real madrid</h6>
                  <img className="logo-team" src={madrid} alt=""></img>
                </div>
                <div>
                  <img className="logo-versus" src={versus} alt=""></img>
                </div>

                <div className="container-team-two">
                  <img className="logo-team" src={girona} alt=""></img>
                  <h6 className="team-two">girona</h6>
                </div>
              </div>

              <div className="container-end end-desktop">
                <div className="progress-bar bar-desktop">
                  <img
                    className="bar-progress-desktop"
                    src={setenta}
                    alt=""
                  ></img>
                  <h6 className="progress-bar-text bar-progress-desktop">
                    72% ocupación
                  </h6>
                  <p className="price-mobile-b">
                    <strong>desde 305€</strong>
                  </p>
                </div>
                <button
                  onClick={() =>
                    window.open(
                      "https://bstadium.es/entradas-de-partidos/entradas-vip-real-madrid-vs-girona-fc-temporada-22-23/"
                    )
                  }
                  className="btn-ticket"
                >
                  <div className="btn-display">
                    <h6 className="btn-text">Comprar entrada VIP + Match Experience</h6>
                    {/* <img className="bufanda" src={bufanda} alt=""></img> */}
                  </div>
                </button>
                <div className="date-desktop">
                  <p>
                    <strong>desde 305€</strong>
                  </p>
                  <p>30/10/2022</p>
                </div>
              </div>
            </div>

             {/* madrid - celtic */}
             <div className="container container-desktop">
              <div className="date-b">
                <div className="progress-bar-b bar-desktop">
                  <img src={noventa} alt=""></img>
                  <h6 className="progress-bar-text">95% ocupación</h6>
                </div>
                <p>2/11/2022</p>
              </div>
              <div className="container-matches">
                <div className="container-team">
                  <h6 className="team-one">real madrid</h6>
                  <img className="logo-team" src={madrid} alt=""></img>
                </div>
                <div>
                  <img className="logo-versus" src={versus} alt=""></img>
                </div>

                <div className="container-team-two">
                  <img className="logo-team" src={celtic} alt=""></img>
                  <h6 className="team-two">celtic</h6>
                </div>
              </div>

              <div className="container-end end-desktop">
                <div className="progress-bar bar-desktop">
                  <img
                    className="bar-progress-desktop"
                    src={noventa}
                    alt=""
                  ></img>
                  <h6 className="progress-bar-text bar-progress-desktop">
                    95% ocupación
                  </h6>
                  <p className="price-mobile-b">
                    <strong>desde 335€</strong>
                  </p>
                </div>
                <button
                  onClick={() =>
                    window.open(
                      "https://bstadium.es/entradas-de-partidos/entradas-vip-real-madrid-vs-celtic-fc-temporada-22-23/"
                    )
                  }
                  className="btn-ticket"
                >
                  <div className="btn-display">
                  <h6 className="btn-text">Comprar entrada VIP + Match Experience</h6>
                    {/* <img className="bufanda" src={bufanda} alt=""></img> */}
                  </div>
                </button>
                <div className="date-desktop">
                  <p>
                    <strong>desde 335€</strong>
                  </p>
                  <p>2/11/2022</p>
                </div>
              </div>
              </div>

            {/* barcelona - almeria */}
            <div className="container container-desktop">
              <div className="date-b">
                <div className="progress-bar-b bar-desktop">
                  <img src={setenta} alt=""></img>
                  <h6 className="progress-bar-text">80% ocupación</h6>
                </div>
                <p>5/11/2022</p>
              </div>
              <div className="container-matches">
                <div className="container-team">
                  <h6 className="team-one">barcelona</h6>
                  <img className="logo-team" src={barcelona} alt=""></img>
                </div>
                <div>
                  <img className="logo-versus" src={versus} alt=""></img>
                </div>

                <div className="container-team-two">
                  <img className="logo-team" src={almeria} alt=""></img>
                  <h6 className="team-two">almeria</h6>
                </div>
              </div>

              <div className="container-end end-desktop">
                <div className="progress-bar bar-desktop">
                  <img
                    className="bar-progress-desktop"
                    src={setenta}
                    alt=""
                  ></img>
                  <h6 className="progress-bar-text bar-progress-desktop">
                    80% ocupación
                  </h6>
                  <p className="price-mobile-b">
                    <strong>desde 34€</strong>
                  </p>
                </div>
                <button
                  onClick={() =>
                    window.open(
                      "https://bstadium.es/entradas-de-partidos/entradas-partido-fc-barcelona-vs-ud-almeria-cf-t-22-23/"
                    )
                  }
                  className="btn-ticket"
                >
                  <div className="btn-display">
                    <h6 className="btn-text">Comprar entrada + Match Experience</h6>
                    {/* <img className="bufanda" src={bufanda} alt=""></img> */}
                  </div>
                </button>
                <div className="date-desktop">
                  <p>
                    <strong>desde 34€</strong>
                  </p>
                  <p>5/11/2022</p>
                </div>
              </div>
            </div>

             {/* atlético madrid - espanyol */}
            <div className="container container-desktop">
              <div className="date-b">
                <div className="progress-bar-b bar-desktop">
                  <img src={noventa} alt=""></img>
                  <h6 className="progress-bar-text">93% ocupación</h6>
                </div>
                <p>6/11/2022</p>
              </div>
              <div className="container-matches">
                <div className="container-team">
                  <h6 className="team-one">atlético madrid</h6>
                  <img className="logo-team" src={atletico} alt=""></img>
                </div>
                <div>
                  <img className="logo-versus" src={versus} alt=""></img>
                </div>

                <div className="container-team-two">
                  <img className="logo-team" src={espanyol} alt=""></img>
                  <h6 className="team-two">espanyol</h6>
                </div>
              </div>

              <div className="container-end end-desktop">
                <div className="progress-bar bar-desktop">
                  <img
                    className="bar-progress-desktop"
                    src={noventa}
                    alt=""
                  ></img>
                  <h6 className="progress-bar-text bar-progress-desktop">
                    93% ocupación
                  </h6>
                  <p className="price-mobile-b">
                    <strong>desde 74€</strong>
                  </p>
                </div>
                <button
                  onClick={() =>
                    window.open(
                      "https://bstadium.es/entradas-de-partidos/entradas-atletico-de-madrid-vs-espanyol-territorio-atleti-gratis/"
                    )
                  }
                  className="btn-ticket"
                >
                  <div className="btn-display">
                  <h6 className="btn-text">Comprar entrada + Territorio Atleti</h6>
                    {/* <img className="bufanda" src={bufanda} alt=""></img> */}
                  </div>
                </button>
                <div className="date-desktop">
                  <p>
                    <strong>desde 74€</strong>
                  </p>
                  <p>6/11/2022</p>
                </div>
              </div>
            </div>

            {/* madrid - cádiz */}
            <div className="container container-desktop">
              <div className="date-b">
                <div className="progress-bar-b bar-desktop">
                  <img src={noventa} alt=""></img>
                  <h6 className="progress-bar-text">89% ocupación</h6>
                </div>
                <p>10/11/2022</p>
              </div>
              <div className="container-matches">
                <div className="container-team">
                  <h6 className="team-one">real madrid</h6>
                  <img className="logo-team" src={madrid} alt=""></img>
                </div>
                <div>
                  <img className="logo-versus" src={versus} alt=""></img>
                </div>

                <div className="container-team-two">
                  <img className="logo-team" src={cadiz} alt=""></img>
                  <h6 className="team-two">cádiz</h6>
                </div>
              </div>

              <div className="container-end end-desktop">
                <div className="progress-bar bar-desktop">
                  <img
                    className="bar-progress-desktop"
                    src={noventa}
                    alt=""
                  ></img>
                  <h6 className="progress-bar-text bar-progress-desktop">
                    89% ocupación
                  </h6>
                  <p className="price-mobile-b">
                    <strong>desde 305€</strong>
                  </p>
                </div>
                <button
                  onClick={() =>
                    window.open(
                      "https://bstadium.es/entradas-de-partidos/entradas-vip-real-madrid-vs-cadiz-cf-temporada-22-23/"
                    )
                  }
                  className="btn-ticket"
                >
                  <div className="btn-display">
                  <h6 className="btn-text">Comprar entrada VIP + Match Experience</h6>
                    {/* <img className="bufanda" src={bufanda} alt=""></img> */}
                  </div>
                </button>
                <div className="date-desktop">
                  <p>
                    <strong>desde 305€</strong>
                  </p>
                  <p>10/11/2022</p>
                </div>
              </div>
            </div>

            

            {/* celta - elche */}
            {/* <div className="container container-desktop">
            <div className="date-b">
                <div className="progress-bar-b bar-desktop">
                  <img src={setenta} alt=""></img>
                  <h6 className="progress-bar-text">73% ocupación</h6>
                </div>
                <p>14/05/2022</p>
              </div>
              <div className="container-matches">
                <div className="container-team">
                  <h6 className="team-one">celta</h6>
                  <img className="logo-team" src={celta} alt=""></img>
                </div>
                <div>
                  <img className="logo-versus" src={versus} alt=""></img>
                </div>

                <div className="container-team-two">
                  <img className="logo-team" src={elche} alt=""></img>
                  <h6 className="team-two">elche</h6>
                </div>
              </div>

              <div className="container-end end-desktop">
              <div className="progress-bar bar-desktop">
                  <img
                    className="bar-progress-desktop"
                    src={setenta}
                    alt=""
                  ></img>
                  <h6 className="progress-bar-text bar-progress-desktop">
                    72% ocupación
                  </h6>
                  <p className="price-mobile-b">
                    <strong>Desde 19€</strong>
                  </p>
                </div>
                <button
                  onClick={() =>
                    window.open(
                      "https://bstadium.es/entradas-de-partidos/entradas-partido-rc-celta-elche/"
                    )
                  }
                  className="btn-ticket"
                >
                  <div className="btn-display">
                    <h6 className="btn-text">Comprar entrada + Regalo</h6>
                    <img className="bufanda" src={bufanda} alt=""></img>
                  </div>
                </button>
                <div className="date-desktop">
                  <p>
                    <strong>Desde 19€</strong>
                  </p>
                  <p>14/05/2022</p>
                </div>
              </div>
            </div> */}

            {/* villareal - real sociedad */}
            {/*  <div className="container container-desktop">
              <div className="date">
                <p className="price-mobile">
                  <strong></strong>
                </p>
                <p>15/05/2022</p>
              </div>
              <div className="container-matches">
                <div className="container-team">
                  <h6 className="team-one">villareal</h6>
                  <img className="logo-team" src={villarreal} alt=""></img>
                </div>
                <div>
                  <img className="logo-versus" src={versus} alt=""></img>
                </div>

                <div className="container-team-two">
                  <img className="logo-team" src={sociedad} alt=""></img>
                  <h6 className="team-two">real sociedad</h6>
                </div>
              </div>

              <div className="container-end end-desktop">
                <div className="progress-bar bar-desktop">
                  <img src={rectangulo} alt=""></img>
                  <h6 className="progress-bar-text">
                    Disponibles<br></br>próximamente
                  </h6>
                </div>
                <button className="btn-ticket no-match">
                  <div className="btn-display">
                    <h6 className="btn-text">Comprar entrada + Regalo</h6>
                    <img className="bufanda" src={bufanda} alt=""></img>
                  </div>
                </button>
                <div className="date-desktop">
                  <p>
                    <strong></strong>
                  </p>
                  <p>15/05/2022</p>
                </div>
              </div>
            </div> */}

            {/* athletic - osasuna */}
            {/* <div className="container container-desktop">
              <div className="date">
                <p className="price-mobile">
                  <strong></strong>
                </p>
                <p>15/05/2022</p>
              </div>
              <div className="container-matches">
                <div className="container-team">
                  <h6 className="team-one">athletic</h6>
                  <img className="logo-team" src={athletic} alt=""></img>
                </div>
                <div>
                  <img className="logo-versus" src={versus} alt=""></img>
                </div>

                <div className="container-team-two">
                  <img className="logo-team" src={osasuna} alt=""></img>
                  <h6 className="team-two">osasuna</h6>
                </div>
              </div>

              <div className="container-end end-desktop">
                <div className="progress-bar bar-desktop">
                  <img src={rectangulo} alt=""></img>
                  <h6 className="progress-bar-text">
                    Disponibles<br></br>próximamente
                  </h6>
                </div>
                <button className="btn-ticket no-match">
                  <div className="btn-display">
                    <h6 className="btn-text">Comprar entrada + Regalo</h6>
                    <img className="bufanda" src={bufanda} alt=""></img>
                  </div>
                </button>
                <div className="date-desktop">
                  <p>
                    <strong></strong>
                  </p>
                  <p>15/05/2022</p>
                </div>
              </div>
            </div> */}

            {/* valladolid - ponferradina */}
            {/* <div className="container container-desktop">
              <div className="date">
                <p className="price-mobile">
                  <strong></strong>
                </p>
                <p>15/05/2022</p>
              </div>
              <div className="container-matches">
                <div className="container-team">
                  <h6 className="team-one">valladolid</h6>
                  <img className="logo-team" src={valladolid} alt=""></img>
                </div>
                <div>
                  <img className="logo-versus" src={versus} alt=""></img>
                </div>

                <div className="container-team-two">
                  <img className="logo-team" src={ponferradina} alt=""></img>
                  <h6 className="team-two">ponferradina</h6>
                </div>
              </div>

              <div className="container-end end-desktop">
                <div className="progress-bar bar-desktop">
                  <img src={rectangulo} alt=""></img>
                  <h6 className="progress-bar-text">
                    Disponibles<br></br>próximamente
                  </h6>
                </div>
                <button className="btn-ticket no-match">
                  <div className="btn-display">
                    <h6 className="btn-text">Comprar entrada + Regalo</h6>
                    <img className="bufanda" src={bufanda} alt=""></img>
                  </div>
                </button>
                <div className="date-desktop">
                  <p>
                    <strong></strong>
                  </p>
                  <p>15/05/2022</p>
                </div>
              </div>
            </div> */}

            {/* atletico - sevilla */}
            {/* <div className="container container-desktop">
              <div className="date-b">
                <div className="progress-bar-b bar-desktop">
                  <img src={noventa} alt=""></img>
                  <h6 className="progress-bar-text">89% ocupación</h6>
                </div>
                <p>15/05/2022</p>
              </div>
              <div className="container-matches">
                <div className="container-team">
                  <h6 className="team-one">atlético</h6>
                  <img className="logo-team" src={atletico} alt=""></img>
                </div>
                <div>
                  <img className="logo-versus" src={versus} alt=""></img>
                </div>

                <div className="container-team-two">
                  <img className="logo-team" src={sevilla} alt=""></img>
                  <h6 className="team-two">sevilla</h6>
                </div>
              </div>

              <div className="container-end end-desktop">
                <div className="progress-bar bar-desktop">
                  <img
                    className="bar-progress-desktop"
                    src={noventa}
                    alt=""
                  ></img>
                  <h6 className="progress-bar-text bar-progress-desktop">
                    89% ocupación
                  </h6>
                  <p className="price-mobile-b">
                    <strong>Desde 89€</strong>
                  </p>
                </div>
                <button
                  onClick={() =>
                    window.open(
                      "https://bstadium.es/entradas-partidos/atletico-de-madrid-vs-sevilla-fc/"
                    )
                  }
                  className="btn-ticket"
                >
                  <div className="btn-display">
                    <h6 className="btn-text">Comprar entrada + Regalo</h6>
                    <img className="bufanda" src={bufanda} alt=""></img>
                  </div>
                </button>
                <div className="date-desktop">
                  <p>
                    <strong>Desde 89€</strong>
                  </p>
                  <p>15/05/2022</p>
                </div>
              </div>
            </div> */}

          
            {/* Levante - alaves */}
           {/*  <div className="container container-desktop">
              <div className="date-b">
                <div className="progress-bar-b bar-desktop">
                  <img src={cincuenta} alt=""></img>
                  <h6 className="progress-bar-text">49% ocupación</h6>
                </div>
                <p>15/05/2022</p>
              </div>
              <div className="container-matches">
                <div className="container-team">
                  <h6 className="team-one">levante</h6>
                  <img className="logo-team" src={levante} alt=""></img>
                </div>
                <div>
                  <img className="logo-versus" src={versus} alt=""></img>
                </div>

                <div className="container-team-two">
                  <img className="logo-team" src={alaves} alt=""></img>
                  <h6 className="team-two">alavés</h6>
                </div>
              </div>

              <div className="container-end end-desktop">
                <div className="progress-bar bar-desktop">
                  <img
                    className="bar-progress-desktop"
                    src={cincuenta}
                    alt=""
                  ></img>
                  <h6 className="progress-bar-text bar-progress-desktop">
                    49% ocupación
                  </h6>
                  <p className="price-mobile-b">
                    <strong>Desde 39€</strong>
                  </p>
                </div>
                <button
                  onClick={() =>
                    window.open(
                      "https://bstadium.es/entradas-de-partidos/entradas-partido-levante-alaves/"
                    )
                  }
                  className="btn-ticket"
                >
                  <div className="btn-display">
                    <h6 className="btn-text">Comprar entrada + Regalo</h6>
                    <img className="bufanda" src={bufanda} alt=""></img>
                  </div>
                </button>
                <div className="date-desktop">
                  <p>
                    <strong>Desde 39€</strong>
                  </p>
                  <p>15/05/2022</p>
                </div>
              </div>
            </div> */}
           {/*  <div className='container-par-main'>
              <div className='container-par'>
                <p><b>Confirmación inmediata de entradas y cupón regalo</b></p> 
                <img className='container-icon' src={tickets} alt=''></img>
              </div>

              <div className='container-par'>
                <p><b>Precios sin comisiones o gastos de gestión</b> (partners oficiales de los clubes)</p>
                <img className='container-icon' src={coins} alt=''></img>

              </div>

              <div className='container-par'>
                <p><b>Disponibilidad de los mejores asientos</b> juntos para parejas o grupos</p>
                <img className='container-seat' src={bestseats} alt=''></img>
              </div>
            </div> */}

            {/* barcelona - villarreal */}
           {/*  <div className="container container-desktop">
              <div className="date-b">
                <div className="progress-bar-b bar-desktop">
                  <img src={noventa} alt=""></img>
                  <h6 className="progress-bar-text">91% ocupación</h6>
                </div>
                <p>22/05/2022</p>
              </div>
              <div className="container-matches">
                <div className="container-team">
                  <h6 className="team-one">barcelona</h6>
                  <img className="logo-team" src={barcelona} alt=""></img>
                </div>
                <div>
                  <img className="logo-versus" src={versus} alt=""></img>
                </div>

                <div className="container-team-two">
                  <img className="logo-team" src={villarreal} alt=""></img>
                  <h6 className="team-two">villarreal</h6>
                </div>
              </div>

              <div className="container-end end-desktop">
                <div className="progress-bar bar-desktop">
                  <img
                    className="bar-progress-desktop"
                    src={noventa}
                    alt=""
                  ></img>
                  <h6 className="progress-bar-text bar-progress-desktop">
                    91% ocupación
                  </h6>
                  <p className="price-mobile-b">
                    <strong>Desde 59€</strong>
                  </p>
                </div>
                <button
                  onClick={() =>
                    window.open(
                      "https://bstadium.es/entradas-partidos/entradas-partido-fc-barcelona-villarreal-cf/"
                    )
                  }
                  className="btn-ticket"
                >
                  <div className="btn-display">
                    <h6 className="btn-text">Comprar entrada + Regalo</h6>
                    <img className="bufanda" src={bufanda} alt=""></img>
                  </div>
                </button>
                <div className="date-desktop">
                  <p>
                    <strong>Desde 59€</strong>
                  </p>
                  <p>22/05/2022</p>
                </div>
              </div>
            </div>   */}    

            {/* madrid - betis */}
            {/* <div className="container container-desktop">
              <div className="date-b">
                <div className="progress-bar-b bar-desktop">
                  <img src={setenta} alt=""></img>
                  <h6 className="progress-bar-text">73% ocupación</h6>
                </div>
                <p>22/05/2022</p>
              </div>
              <div className="container-matches">
                <div className="container-team">
                  <h6 className="team-one">real madrid</h6>
                  <img className="logo-team" src={madrid} alt=""></img>
                </div>
                <div>
                  <img className="logo-versus" src={versus} alt=""></img>
                </div>

                <div className="container-team-two">
                  <img className="logo-team" src={betis} alt=""></img>
                  <h6 className="team-two">betis</h6>
                </div>
              </div>

              <div className="container-end end-desktop">
                <div className="progress-bar bar-desktop">
                  <img
                    className="bar-progress-desktop"
                    src={setenta}
                    alt=""
                  ></img>
                  <h6 className="progress-bar-text bar-progress-desktop">
                    73% ocupación
                  </h6>
                  <p className="price-mobile-b">
                    <strong>425€</strong>
                  </p>
                </div>
                <button
                  onClick={() =>
                    window.open(
                      "https://bstadium.es/entradas-partidos/entradas-vip-real-madrid-real-betis-balompie/"
                    )
                  }
                  className="btn-ticket"
                >
                  <div className="btn-display">
                    <h6 className="btn-text">Comprar entrada + Regalo</h6>
                    <img className="bufanda" src={bufanda} alt=""></img>
                  </div>
                </button>
                <div className="date-desktop">
                  <p>
                    <strong>425€</strong>
                  </p>
                  <p>22/05/2022</p>
                </div>
              </div>
            </div> */}

             {/* madrid - betis */}
            {/*  <div className="container container-desktop">
              <div className="date-b">
                <div className="progress-bar-b bar-desktop">
                  <img src={cincuenta} alt=""></img>
                  <h6 className="progress-bar-text">58% ocupación</h6>
                </div>
                <p>22/05/2022</p>
              </div>
              <div className="container-matches">
                <div className="container-team">
                  <h6 className="team-one">málaga</h6>
                  <img className="logo-team" src={malaga} alt=""></img>
                </div>
                <div>
                  <img className="logo-versus" src={versus} alt=""></img>
                </div>

                <div className="container-team-two">
                  <img className="logo-team" src={burgos} alt=""></img>
                  <h6 className="team-two">burgos</h6>
                </div>
              </div>

              <div className="container-end end-desktop">
                <div className="progress-bar bar-desktop">
                  <img
                    className="bar-progress-desktop"
                    src={cincuenta}
                    alt=""
                  ></img>
                  <h6 className="progress-bar-text bar-progress-desktop">
                    58% ocupación
                  </h6>
                  <p className="price-mobile-b">
                    <strong>Desde 15€</strong>
                  </p>
                </div>
                <button
                  onClick={() =>
                    window.open(
                      "https://bstadium.es/experiencias-con-entradas/entradas-partido-malaga-vs-burgos-cf/"
                    )
                  }
                  className="btn-ticket"
                >
                  <div className="btn-display">
                    <h6 className="btn-text">Comprar entrada + Regalo</h6>
                    <img className="bufanda" src={bufanda} alt=""></img>
                  </div>
                </button>
                <div className="date-desktop">
                  <p>
                    <strong>Desde 15€</strong>
                  </p>
                  <p>22/05/2022</p>
                </div>
              </div>
            </div> */}

            {/* sevilla - athletic */}
           {/*  <div className="container container-desktop">
            <div className="date-b">
                <div className="progress-bar-b bar-desktop">
                  <img src={noventa} alt=""></img>
                  <h6 className="progress-bar-text">92% ocupación</h6>
                </div>
                <p>22/05/2022</p>
              </div>
              <div className="container-matches">
                <div className="container-team">
                  <h6 className="team-one">sevilla</h6>
                  <img className="logo-team" src={sevilla} alt=""></img>
                </div>
                <div>
                  <img className="logo-versus" src={versus} alt=""></img>
                </div>

                <div className="container-team-two">
                  <img className="logo-team" src={athletic} alt=""></img>
                  <h6 className="team-two">athletic</h6>
                </div>
              </div>

              <div className="container-end end-desktop">
              <div className="progress-bar bar-desktop">
                  <img
                    className="bar-progress-desktop"
                    src={noventa}
                    alt=""
                  ></img>
                  <h6 className="progress-bar-text bar-progress-desktop">
                    92% ocupación
                  </h6>
                  <p className="price-mobile-b">
                    <strong>Desde 20€</strong>
                  </p>
                </div>
                <button
                  onClick={() =>
                    window.open(
                      "https://bstadium.es/sevilla-fc/entradas-partido-sevilla-fc-athletic-club/"
                    )
                  }
                  className="btn-ticket"
                >
                  <div className="btn-display">
                    <h6 className="btn-text">Comprar entrada + Regalo</h6>
                    <img className="bufanda" src={bufanda} alt=""></img>
                  </div>
                </button>
                <div className="date-desktop">
                  <p>
                    <strong>Desde 20€</strong>
                  </p>
                  <p>22/05/2022</p>
                </div>
              </div>
            </div> */}

            {/* granada - espanyol */}
            {/*  <div className="container container-desktop">
              <div className="date">
                <p className="price-mobile">
                  <strong></strong>
                </p>
                <p>22/05/2022</p>
              </div>
              <div className="container-matches">
                <div className="container-team">
                  <h6 className="team-one">granada</h6>
                  <img className="logo-team" src={granada} alt=""></img>
                </div>
                <div>
                  <img className="logo-versus" src={versus} alt=""></img>
                </div>

                <div className="container-team-two">
                  <img className="logo-team" src={espanyol} alt=""></img>
                  <h6 className="team-two">espanyol</h6>
                </div>
              </div>

              <div className="container-end end-desktop">
                <div className="progress-bar bar-desktop">
                  <img src={rectangulo} alt=""></img>
                  <h6 className="progress-bar-text">
                    Disponibles<br></br>próximamente
                  </h6>
                </div>
                <button className="btn-ticket no-match">
                  <div className="btn-display">
                    <h6 className="btn-text">Comprar entrada + Regalo</h6>
                    <img className="bufanda" src={bebida} alt=""></img>
                  </div>
                </button>
                <div className="date-desktop">
                  <p>
                    <strong></strong>
                  </p>
                  <p>22/05/2022</p>
                </div>
              </div>
            </div> */}

            {/* granada - espanyol */}
            {/* <div className="container container-desktop">
              <div className="date">
                <p className="price-mobile">
                  <strong></strong>
                </p>
                <p>22/05/2022</p>
              </div>
              <div className="container-matches">
                <div className="container-team">
                  <h6 className="team-one">espanyol</h6>
                  <img className="logo-team" src={espanyol} alt=""></img>
                </div>
                <div>
                  <img className="logo-versus" src={versus} alt=""></img>
                </div>

                <div className="container-team-two">
                  <img className="logo-team" src={valencia} alt=""></img>
                  <h6 className="team-two">valencia</h6>
                </div>
              </div>

              <div className="container-end end-desktop">
                <div className="progress-bar bar-desktop">
                  <img src={rectangulo} alt=""></img>
                  <h6 className="progress-bar-text">
                    Disponibles<br></br>próximamente
                  </h6>
                </div>
                <button className="btn-ticket no-match">
                  <div className="btn-display">
                    <h6 className="btn-text">Comprar entrada + Regalo</h6>
                    <img className="bufanda" src={bebida} alt=""></img>
                  </div>
                </button>
                <div className="date-desktop">
                  <p>
                    <strong></strong>
                  </p>
                  <p>22/05/2022</p>
                </div>
              </div>
            </div> */}

            {/* osasuna - mallorca */}
            {/* <div className="container container-desktop">
              <div className="date">
                <p className="price-mobile">
                  <strong></strong>
                </p>
                <p>22/05/2022</p>
              </div>
              <div className="container-matches">
                <div className="container-team">
                  <h6 className="team-one">osasuna</h6>
                  <img className="logo-team" src={osasuna} alt=""></img>
                </div>
                <div>
                  <img className="logo-versus" src={versus} alt=""></img>
                </div>

                <div className="container-team-two">
                  <img className="logo-team" src={mallorca} alt=""></img>
                  <h6 className="team-two">mallorca</h6>
                </div>
              </div>

              <div className="container-end end-desktop">
                <div className="progress-bar bar-desktop">
                  <img src={rectangulo} alt=""></img>
                  <h6 className="progress-bar-text">
                    Disponibles<br></br>próximamente
                  </h6>
                </div>
                <button className="btn-ticket no-match">
                  <div className="btn-display">
                    <h6 className="btn-text">Comprar entrada + Regalo</h6>
                    <img className="bufanda" src={bufanda} alt=""></img>
                  </div>
                </button>
                <div className="date-desktop">
                  <p>
                    <strong></strong>
                  </p>
                  <p>22/05/2022</p>
                </div>
              </div>
            </div> */}

            {/* valladolid - huesca */}
            {/* <div className="container container-desktop">
              <div className="date">
                <p className="price-mobile">
                  <strong></strong>
                </p>
                <p>29/05/2022</p>
              </div>
              <div className="container-matches">
                <div className="container-team">
                  <h6 className="team-one">valladolid</h6>
                  <img className="logo-team" src={valladolid} alt=""></img>
                </div>
                <div>
                  <img className="logo-versus" src={versus} alt=""></img>
                </div>

                <div className="container-team-two">
                  <img className="logo-team" src={huesca} alt=""></img>
                  <h6 className="team-two">huesca</h6>
                </div>
              </div>

              <div className="container-end end-desktop">
                <div className="progress-bar bar-desktop">
                  <img src={rectangulo} alt=""></img>
                  <h6 className="progress-bar-text">
                    Disponibles<br></br>próximamente
                  </h6>
                </div>
                <button className="btn-ticket no-match">
                  <div className="btn-display">
                    <h6 className="btn-text">Comprar entrada + Regalo</h6>
                    <img className="bufanda" src={bufanda} alt=""></img>
                  </div>
                </button>
                <div className="date-desktop">
                  <p>
                    <strong></strong>
                  </p>
                  <p>29/05/2022</p>
                </div>
              </div>
            </div> */}
          </div>
        ) : null}

        {/* -----------PAGINA 2------------ */}
        {/* {page === 2 ? (
          <div className="container-two">                                             


          </div>
        ) : null} */}

        {/* -----------PAGINA 3 ------------- */}
        {/*  {page === 3 ? (
          <div className="container-three">
                        
            

          </div>
        ) : null} */}

        {/* -----------PAGINA 4 ------------- */}
        {/* {page === 4 ? (
          <div className="container-three">

          
          </div>
        ) : null} */}

        {/* -----------PAGINA 5 ------------- 
        {page === 5 ? (
          <div className="container-three">
            
          </div>
        ) : null} */}

        {/* <div className="buttons">
          <button className="btn-style" onClick={prev}>
            <FontAwesomeIcon icon={faAngleLeft} style={{ color: "#838383" }} />
          </button> */}
         {/*  <button className="btn-style" onClick={pageOne}>
            1
          </button> */}
         {/*  <button className="btn-style" onClick={pageTwo}>
            2
          </button> */}
          {/*  <button className="btn-style" onClick={pageThree}>
            3
          </button> */}
          {/* <button className="btn-style" onClick={pageFour}>
            4
          </button> */}
          {/* <button className="btn-style" onClick={pageFive}>
            5
          </button> */}
          {/* <button className="btn-style" onClick={next}>
            <FontAwesomeIcon icon={faAngleRight} style={{ color: "#838383" }} />
          </button>
        </div> */}
      </div>
      {/* <div className='container-par-main custom-margin'>
         <div className='container-par'>
          <p><b>Confirmación inmediata de entradas y cupón regalo</b></p> 
          <img className='container-icon' src={tickets} alt=''></img>
         </div>

         <div className='container-par'>
           <p><b>Precios sin comisiones o gastos de gestión</b> (partners oficiales de los clubes)</p>
           <img className='container-icon' src={coins} alt=''></img>

         </div>

         <div className='container-par'>
           <p><b>Disponibilidad de los mejores asientos</b> juntos para parejas o grupos</p>
           <img className='container-seat' src={bestseats} alt=''></img>
         </div>
       </div> */}
    </div>
  );
}

export default Matches;
